import React from "react";
import { Radio, Textarea } from "../..";

// const truncate = (n, len) => {
//   let ext = n.substring(n.lastIndexOf(".") + 1, n.length).toLowerCase();
//   let filename = n.replace("." + ext, "");
//   if (filename.length <= len) {
//     return n;
//   }
//   filename = filename.substr(0, len) + (n.length > len ? "[...]" : "");
//   return filename + "." + ext;
// };

const ThirdStep = ({ register, errors, step, setStep }) => {
  // const [files, setFiles] = useState([]);
  return (
    <div
      className="step_3 stepWrapper"
      style={{ display: step === 3 ? "block" : "none" }}
    >
      <h3 className="step-title">Informacje o rynku i marce</h3>
      <div className="row brief_row">
        <div className="col-md-5">
          <h4>Profil firmy</h4>
          <p>
            Opisz w kilku słowach profil swojej firmy, obszar działania,
            oferowane towary/usługi, na czym polega Twój biznes.
          </p>
        </div>
        <div className="col-md-6 offset-md-1">
          <Textarea
            cols="40"
            rows="3"
            name="company_profile"
            label="Profil firmy"
            register={register}
            required={true}
            errors={errors}
          />
        </div>
      </div>
      <div className="row brief_row">
        <div className="col-md-5">
          <h4>Grupa docelowa</h4>
          <p>Do kogo kierujesz swoją markę/swój produkt?</p>
        </div>
        <div className="col-md-6 offset-md-1">
          <Textarea
            cols="40"
            rows="3"
            name="target_group"
            label="Grupa docelowa"
            register={register}
            required={true}
            errors={errors}
          />
        </div>
      </div>
      <div className="row brief_row">
        <div className="col-md-5">
          <h4>Otoczenie konkurencyjne</h4>
          <p>
            Prosimy o podanie linków do stron www konkurencji, zarówno tej
            najbliższej, osiągalnej, jak i wiodącej na rynku.
          </p>
        </div>
        <div className="col-md-6 offset-md-1">
          <Textarea
            cols="40"
            rows="3"
            name="competition"
            label="Otoczenie konkurencyjne"
            register={register}
            required={true}
            errors={errors}
          />
        </div>
      </div>
      <div className="row brief_row">
        <div className="col-md-5">
          <h4>USP</h4>
          <p>Co wyróżnia Twój produkt spośród konkurencji?</p>
        </div>
        <div className="col-md-6 offset-md-1">
          <Textarea
            cols="40"
            rows="3"
            name="unique_features"
            label="Co wyróżnia Twój produkt spośród konkurencji?."
            register={register}
            required={true}
            errors={errors}
          />
        </div>
      </div>
      <div className="row brief_row">
        <div className="col-md-5">
          <h4>Obszar działania</h4>
          <p>
            Na terenie jakiej lokalizacji chciałbyś prowadzić działania
            marketingowe? Wskaż kraj, województwo lub konkretne miasta, jeżeli
            twój biznes jest lokalny.
          </p>
        </div>
        <div className="col-md-6 offset-md-1">
          <Textarea
            cols="40"
            rows="3"
            name="work_area"
            label="Obszar działania"
            register={register}
            required={true}
            errors={errors}
          />
        </div>
      </div>
      <div className="row brief_row">
        <div className="col-md-5">
          <h4>Ekspansja na rynki zagraniczne</h4>
          <p>
            Czy prowadzisz sprzedaz swoich produktów na rynkach zagranicznych.{" "}
            <br />
            Czy w najbliższym czasie planujesz ekspansję na inne rynki?
          </p>
        </div>
        <div className="col-md-6 offset-md-1">
          <Radio
            name="expansion"
            label="Tak"
            id="expansion_yeas"
            register={register}
            errors={null}
          />
          <Radio
            name="expansion"
            label="Nie"
            id="expansion_no"
            register={register}
            errors={null}
          />
          <Radio
            name="expansion"
            label="Nie, ale planuję"
            id="expansion_maybe"
            register={register}
            errors={null}
          />
        </div>
      </div>
      <div className="row brief_row">
        <div className="col-md-5">
          <h4>Sezonowość</h4>
          <p>
            Czy sprzedaż produktów lub usług oferowanych przez Ciebie jest
            sezonowa? <br />
            Jeżeli tak, opowiedz nam więcej o sezonowości Twoich produktów
          </p>
        </div>
        <div className="col-md-6 offset-md-1">
          <Textarea
            cols="40"
            rows="3"
            name="seasons"
            label="Sezonowość"
            register={register}
            required={true}
            errors={errors}
          />
        </div>
      </div>
      {
        // <div className="row brief_row">
        //   <div className="col-md-5">
        //     <h4>
        //       Czy posiadasz dodatkowe materiały, takie jak: strategia marki,
        //       strategia komunikacji, brandbook / księga znaku
        //     </h4>
        //     <p>
        //       Te materiały mogą być przydatne podczas tworzenia naszej wstępnej
        //       strategii prowadzenia wybranego przez Ciebie kanału social media.
        //     </p>
        //   </div>
        //   <div className="col-md-6 offset-md-1">
        //     <div className="attachments attachments--brief">
        //       <input
        //         type="file"
        //         id="upload"
        //         name="upload"
        //         aria-label="Dodaj plik"
        //         multiple
        //         // ref={register({ required: "Proszę załączyć CV" })}
        //         // errors={errors}
        //         onChange={(e) => setFiles(e.target.files)}
        //         style={{ display: "none" }}
        //       />
        //       <label htmlFor="upload" className="btn btn-box">
        //         załącz <strong>pliki</strong>
        //       </label>
        //       {files && files.length > 0 && (
        //         <div className="files-uploaded">
        //           <div className="row">
        //             <div className="col-lg-6">
        //               {files.length >= 1 ? (
        //                 <div className="single-file">
        //                   <div className="single-file__icon"></div>
        //                   <div className="file-status">
        //                     <span className="file-status__name" id="file1">
        //                       {truncate(files[0].name, 10)}
        //                     </span>
        //                     <span className="file-status__bar"></span>
        //                   </div>
        //                 </div>
        //               ) : (
        //                 ""
        //               )}
        //             </div>
        //             <div className="col-lg-6">
        //               {files.length >= 2 ? (
        //                 <div className="single-file">
        //                   <div className="single-file__icon"></div>
        //                   <div className="file-status">
        //                     <span className="file-status__name">
        //                       {truncate(files[1].name, 10)}
        //                     </span>
        //                     <span className="file-status__bar"></span>
        //                   </div>
        //                 </div>
        //               ) : (
        //                 ""
        //               )}
        //             </div>
        //             <div className="col-lg-6">
        //               {files.length >= 3 ? (
        //                 <div className="single-file">
        //                   <div className="single-file__icon"></div>
        //                   <div className="file-status">
        //                     <span className="file-status__name">
        //                       {truncate(files[2].name, 10)}
        //                     </span>
        //                     <span className="file-status__bar"></span>
        //                   </div>
        //                 </div>
        //               ) : (
        //                 ""
        //               )}
        //             </div>
        //             <div className="col-lg-6">
        //               {files.length >= 4 ? (
        //                 <div className="single-file">
        //                   <div className="single-file__icon"></div>
        //                   <div className="file-status">
        //                     <span className="file-status__name">
        //                       {truncate(files[3].name, 10)}
        //                     </span>
        //                     <span className="file-status__bar"></span>
        //                   </div>
        //                 </div>
        //               ) : (
        //                 ""
        //               )}
        //             </div>
        //           </div>
        //         </div>
        //       )}
        //       {errors && errors.upload && (
        //         <p style={{ fontSize: "12px", color: "#4a059c" }}>
        //           {errors.upload.message}
        //         </p>
        //       )}
        //     </div>
        //     <p className="attachments-small-info attachments-small-info--brief">
        //       Maksymalnie 4 pliki
        //     </p>
        //   </div>
        // </div>
      }
      <div className="btn-brief-group">
        <button
          type="button"
          className="btn btn-box"
          onClick={() => setStep(2)}
        >
          poprzedni <strong>krok</strong>
        </button>

        <button
          type="button"
          className="btn btn-box"
          onClick={() => setStep(4)}
        >
          kolejny <strong>krok</strong>
        </button>
      </div>
    </div>
  );
};

export default ThirdStep;
