import React from "react";
import { Input, Textarea, Check } from "../..";
import $ from "jquery";


const SecondStep = ({ register, errors, step, setStep }) => {
  if (typeof window !== `undefined`) {
    $('#check_others').click(function(){
      if ($("#check_others").is(':checked')) {
        $('.hidden-row').css('display', 'flex')
      } else {
        $('.hidden-row').css('display', 'none')
      }
    });
  }
  return (
    <div
      className="step_2 stepWrapper"
      style={{ display: step === 2 ? "block" : "none" }}
    >
      <h3 className="step-title">Kanały komunikacji</h3>
      <div className="row brief_row">
        <div className="col-md-5">
          <p>W jakich mediach społecznościowych prowadzisz obecnie profile firmowe:</p>
        </div>
      </div>
      <div className="row brief_row">
        <div className="col-md-5">
          <h4>Facebook</h4>
        </div>
        <div className="col-md-6 offset-md-1">
          <Input
            type="text"
            name="facebook_link"
            label="Tutaj dodaj link strony na Facebooku Twojej firmy"
            register={register}
            errors={errors}
          />
        </div>
      </div>
      <div className="row brief_row">
        <div className="col-md-5">
          <h4>Instagram</h4>
        </div>
        <div className="col-md-6 offset-md-1">
          <Input
            type="text"
            name="instagram_link"
            label="Tutaj dodaj link konta Instagram Twojej firmy"
            register={register}
            errors={errors}
          />
        </div>
      </div>
      <div className="row brief_row">
        <div className="col-md-5">
          <h4>LinkedIn</h4>
        </div>
        <div className="col-md-6 offset-md-1">
          <Input
            type="text"
            name="linkedin_link"
            label="Tutaj dodaj link strony na LinkedIn Twojej firmy"
            register={register}
            errors={errors}
          />
        </div>
      </div>
      <div className="row brief_row">
        <div className="col-md-5">
          <h4>Inne</h4>
        </div>
        <div className="col-md-6 offset-md-1">
          <Input
            type="text"
            name="other_link"
            label="Tutaj dodaj link do innych profili społecznościowych"
            register={register}
            errors={errors}
          />
        </div>
      </div>
      <div className="row brief_row">
        <div className="col-md-5">
          <h4>Prowadzenie social media</h4>
          <p>
            W jakich mediach społecznościowych chciałbyś, abyśmy prowadzili Twoje profile firmowe 
          </p>
        </div>
        <div className="col-md-6 offset-md-1">
          <Check
            register={register}
            name="check_facebook"
            errors={errors}
            label={
              <p>
                Facebook
              </p>
            }
          />
          <Check
            register={register}
            name="check_instagram"
            errors={errors}
            label={
              <p>
                Instagram
              </p>
            }
          />
          <Check
            register={register}
            name="check_linkedin"
            errors={errors}
            label={
              <p>
                LinkedIn
              </p>
            }
          />
          <Check
            register={register}
            name="check_others"
            id="check_others"
            errors={errors}
            label={
              <p>
                Inne
              </p>
            }
          />
        </div>
      </div>
      <div className="row brief_row adwords-access-row hidden-row">
        <div className="col-md-5"></div>
        <div className="col-md-6 offset-md-1">
          <Input
            type="text"
            name="other_socials"
            label="Wymień inne media społecznościowe"
            register={register}
            errors={errors}
          />
        </div>
      </div>
      <div className="row brief_row">
        <div className="col-md-5">
          <h4>Inne kanały komunikacji</h4>
          <p>
          Wskaż inne kanały, za pomocą których komunikujesz się z potencjalnymi klientami
          </p>
        </div>
        <div className="col-md-6 offset-md-1">
          <Textarea
            cols="40"
            rows="3"
            name="other_socials_canals"
            label="Inne kanały komunikacji"
            register={register}
            required={true}
            errors={errors}
          />
        </div>
      </div>
      <div className="btn-brief-group">
        <button
          type="button"
          className="btn btn-box"
          onClick={() => setStep(1)}
        >
          poprzedni <strong>krok</strong>
        </button>
        <button
          className="btn btn-box"
          type="button"
          onClick={() => setStep(3)}
        >
          kolejny <strong>krok</strong>
        </button>
      </div>
    </div>
  );
};

export default SecondStep;
